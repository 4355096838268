import React, { createContext, useContext, useEffect, useState } from 'react';
import { colors } from '@lamimed/pantheon';

import { useIsWidget } from 'hooks/useIsWidget';
import { useWidgetConfig } from 'hooks/useWidgetStyles';

interface ScheduleData {
  sessionId: string;
  productId: string;
  productSlug: string;
}

interface ScheduleCtx extends ScheduleData {
  fillScheduleData: (data: ScheduleData) => void;
  primaryColor: string;
  textColor: string;
  clearScheduleData: () => void;
}

const Schedule = createContext({} as ScheduleCtx);

export const ScheduleProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [sessionId, setSessionId] = useState('');
  const [productId, setProductId] = useState('');
  const [productSlug, setProductSlug] = useState('');
  const [primaryColor, setPrimaryColor] = useState('');
  const [textColor, setTextColor] = useState('');

  const { isWidget } = useIsWidget();
  const { theme } = useWidgetConfig();

  const fillScheduleData = ({
    sessionId,
    productId,
    productSlug,
  }: ScheduleData) => {
    setSessionId(sessionId);

    setProductId(productId);

    setProductSlug(productSlug);
  };

  const clearScheduleData = () => {
    setSessionId('');

    setProductId('');

    setProductSlug('');
  };

  useEffect(() => {
    if (!isWidget) {
      return;
    }

    setPrimaryColor(theme.primaryColorElements);

    const convertedTextColor =
      theme.textColor === colors.dark ? 'dark' : 'light';

    setTextColor(convertedTextColor);
  }, [isWidget, theme]);

  return (
    <Schedule.Provider
      value={{
        sessionId,
        productId,
        productSlug,
        fillScheduleData,
        primaryColor,
        textColor,
        clearScheduleData,
      }}
    >
      {children}
    </Schedule.Provider>
  );
};

export const useSchedule = () => {
  const context = useContext(Schedule);

  if (!context) {
    throw new Error('useSchedule hooke must be used under ScheduleProvider');
  }

  return context;
};
