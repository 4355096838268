import { defaultStyles } from '../defaultStyles';

export const validateLogo = (
  logoWhiteUrl?: string,
  logoColorfulUrl?: string,
) => {
  const defaultValues = {
    logoWhite: defaultStyles.logoWhite,
    logoColorful: defaultStyles.logoColorful,
  };

  if (!logoWhiteUrl || !logoColorfulUrl) {
    return defaultValues;
  }

  if (
    !logoWhiteUrl?.match(/\.(png|webp|svg)$/) ||
    !logoColorfulUrl?.match(/\.(png|webp|svg)$/)
  ) {
    console.error(
      'Invalid logoWhite: file must be an image with .png, .svg or .webp extension',
    );

    return defaultValues;
  }

  return {
    logoWhite: logoWhiteUrl,
    logoColorful: logoColorfulUrl,
  };
};
