import { validateLogo } from '../validateLogo';
import { validatePrimaryColor } from '../validatePrimaryColor';
import { validateTextColor } from '../validateTextColor';

import { WidgetTheme } from '..';

export const validateStyles = (themeOptions?: WidgetTheme) => {
  if (!themeOptions) {
    return;
  }

  const { logoWhite, logoColorful } = validateLogo(
    themeOptions.logoWhite,
    themeOptions.logoColorful,
  );

  const { primaryColorElements, primaryColorHeaders } = validatePrimaryColor(
    themeOptions.primaryColor,
  );

  const textColor = validateTextColor(themeOptions.textColor);

  return {
    logoWhite,
    logoColorful,
    primaryColorElements,
    primaryColorHeaders,
    textColor,
  };
};
