import { CSSReset as PantheonReset } from '@lamimed/pantheon';
import { createGlobalStyle } from 'styled-components';

export const PantheonButtonHeight = '4rem';

const CSSReset = createGlobalStyle`
  * {
    -webkit-tap-highlight-color: transparent;
  }

  html {
    scroll-behavior: smooth;
  }
`;

export const GlobalStyles = () => (
  <>
    <PantheonReset />

    <CSSReset />
  </>
);
