import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { WidgetTheme } from '.';

export const useCustomStyles = () => {
  const [widgetCustomStyles, setWidgetCustomStyles] = useState(
    {} as WidgetTheme,
  );
  const {
    query: { primaryColor, textColor, logoWhite, logoColorful },
  } = useRouter();

  const router = useRouter();

  useEffect(() => {
    if (!router.isReady) {
      return;
    }

    setWidgetCustomStyles({
      primaryColor: primaryColor as string,
      textColor: textColor as string,
      logoWhite: logoWhite as string,
      logoColorful: logoColorful as string,
    });
  }, [primaryColor, textColor, logoWhite, logoColorful, router.isReady]);

  return { widgetCustomStyles };
};
