import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useViewport } from '@lamimed/pantheon';

interface MenuCtx {
  isMenuOpen: boolean;
  openMenu: boolean;
  handleCloseMenu: () => void;
  handleToggleMenu: () => void;
  handleOpenMenu: () => void;
}

const MenuContext = createContext({} as MenuCtx);

export const MenuProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [openMenu, setOpenMenu] = useState(false);

  const { greaterThan } = useViewport();

  const handleToggleMenu = () => {
    setOpenMenu((current) => !current);
  };

  const handleCloseMenu = () => {
    setOpenMenu(false);
  };

  const handleOpenMenu = () => {
    setOpenMenu(true);
  };

  useEffect(() => {
    if (greaterThan('tablet')) {
      return;
    }

    document.body.style.overflowY = 'auto';

    if (openMenu) {
      document.body.style.overflowY = 'hidden';
    }
  }, [openMenu, greaterThan]);

  const menuProviderValues = useMemo(
    () => ({
      isMenuOpen: openMenu,
      openMenu,
      handleCloseMenu,
      handleToggleMenu,
      handleOpenMenu,
    }),
    [openMenu],
  );

  return (
    <MenuContext.Provider value={menuProviderValues}>
      {children}
    </MenuContext.Provider>
  );
};

export const useMenu = () => {
  const context = useContext(MenuContext);

  if (!context) {
    throw new Error('useMenu hook must be used under MenuProvider');
  }

  return context;
};
