import { colors } from '@lamimed/pantheon';

import { defaultStyles } from '../defaultStyles';

type TextColor = 'dark' | 'light';

export const validateTextColor = (textColor?: string) => {
  if (!textColor) {
    return defaultStyles.textColor;
  }

  if (!textColor.match(/^(dark|light)$/)) {
    console.error('Invalid textColor: it must be "dark" or "light".');

    return defaultStyles.textColor;
  }

  return colors[textColor as TextColor];
};
