import { createContext, useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { useIsWidget } from 'hooks/useIsWidget';

import { EventName, triggerWidgetEvent } from 'utils/triggerWidgetEvent';

import { defaultStyles } from './defaultStyles';
import { useCustomStyles } from './useCustomStyles';
import { validateStyles } from './validateStyles';

export interface WidgetTheme {
  logoColorful?: string;
  logoWhite?: string;
  primaryColor?: string;
  textColor?: string;
}

export interface SymptomCheckerThemeProps {
  logoColorful: string;
  logoWhite: string;
  primaryColorElements: string;
  primaryColorHeaders: string;
  textColor: string;
}

interface WidgetCtx {
  theme: SymptomCheckerThemeProps;
}

const Widget = createContext({} as WidgetCtx);

const LAMI_WIDGET_STYLES = 'lamiWidgetStyles';

export const WidgetProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { widgetCustomStyles } = useCustomStyles();
  const { isWidget, isWidgetFirstRender } = useIsWidget();

  const [theme, setTheme] = useState<SymptomCheckerThemeProps>(defaultStyles);

  const router = useRouter();

  useEffect(() => {
    const validatedStyles = validateStyles(widgetCustomStyles);

    if (!isWidgetFirstRender) {
      return;
    }

    localStorage.setItem(
      LAMI_WIDGET_STYLES,
      JSON.stringify({ ...defaultStyles, ...validatedStyles }),
    );

    setTheme((current) => ({
      ...current,
      ...validatedStyles,
    }));
  }, [isWidgetFirstRender, widgetCustomStyles]);

  useEffect(() => {
    if (!isWidget) return;

    const localConfig = JSON.parse(
      String(localStorage.getItem(LAMI_WIDGET_STYLES)),
    ) as SymptomCheckerThemeProps;

    if (localConfig) {
      setTheme((current) => ({ ...current, ...localConfig }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isWidget || !router.isReady) {
      return;
    }

    /**
     * NOTE: This check makes sure to send the onLoadFinish message to remove the widget spinner only after
     * the custom theme has been applied, on the first render the default styles are always rendered
     */
    if (!isWidgetFirstRender) {
      triggerWidgetEvent({ eventName: EventName.onLoadFinish, body: theme });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theme, isWidgetFirstRender]);

  useEffect(() => {
    if (!isWidget || !router.isReady) {
      return;
    }

    const localConfig = JSON.parse(
      String(localStorage.getItem(LAMI_WIDGET_STYLES)),
    ) as SymptomCheckerThemeProps;

    if (localConfig) {
      setTheme((current) => ({ ...current, ...localConfig }));

      triggerWidgetEvent({ eventName: EventName.onLoadFinish, body: theme });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWidget, router]);

  return <Widget.Provider value={{ theme }}>{children}</Widget.Provider>;
};

export const useWidgetConfig = () => {
  const context = useContext(Widget);

  if (!context) {
    throw new Error('useWidgetConfig hook must be used under WidgetProvider');
  }

  return context;
};
