import React, { createContext, useContext, useState } from 'react';

import { SeriousnessLevel } from 'utils/enums/seriousnessLevel';

export type BiologicalSex = 'F' | 'M';

type FillSession = { ticketId: string };

interface SymptomCheckerPatient {
  biologicalSex?: BiologicalSex;
  birthdate?: number;
}

interface Result {
  seriousness_level: SeriousnessLevel;
  telemedicine_eligible: boolean;
  answer: {
    diagnosis: string;
    advices: string[];
  };
}

interface ClearSession {
  clearTerms?: boolean;
}

interface SymptomCheckerCtx {
  symptomCheckerPatient: SymptomCheckerPatient;
  setSymptomCheckerPatient: React.Dispatch<
    React.SetStateAction<SymptomCheckerPatient>
  >;
  sessionId: string;
  isCurrentFormValid: boolean;
  setIsCurrentFormValid: React.Dispatch<React.SetStateAction<boolean>>;
  isRequestLoading: boolean;
  setIsRequestLoading: React.Dispatch<React.SetStateAction<boolean>>;
  fillSession: (params: FillSession) => void;
  clearSession: (params?: ClearSession) => void;
  resultInfo: Result;
  setResultInfo: React.Dispatch<React.SetStateAction<Result>>;
  isAuthenticated: boolean;
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
}

const SymptomChecker = createContext({} as SymptomCheckerCtx);

export const SymptomCheckerProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [symptomCheckerPatient, setSymptomCheckerPatient] = useState(
    {} as SymptomCheckerPatient,
  );
  const [sessionId, setSessionId] = useState('');
  const [isCurrentFormValid, setIsCurrentFormValid] = useState(false);
  const [isRequestLoading, setIsRequestLoading] = useState(false);
  const [resultInfo, setResultInfo] = useState({} as Result);

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const fillSession = ({ ticketId }: FillSession) => {
    setSessionId(ticketId);
  };

  const clearSession = () => {
    setSessionId('');

    setIsCurrentFormValid(false);

    setIsRequestLoading(false);
  };

  return (
    <SymptomChecker.Provider
      value={{
        symptomCheckerPatient,
        setSymptomCheckerPatient,
        isCurrentFormValid,
        setIsCurrentFormValid,
        isRequestLoading,
        setIsRequestLoading,
        fillSession,
        clearSession,
        sessionId,
        resultInfo,
        setResultInfo,
        isAuthenticated,
        setIsAuthenticated,
      }}
    >
      {children}
    </SymptomChecker.Provider>
  );
};

export const useSymptomChecker = () => {
  const context = useContext(SymptomChecker);

  if (!context) {
    throw new Error(
      'useSymptomChecker hook must be used under SymptomCheckerProvider',
    );
  }

  return context;
};
