import { validateHexColor } from 'utils/validateHexColor';

import { defaultStyles } from '../defaultStyles';

export const validatePrimaryColor = (primaryColor?: string) => {
  const defaultValues = {
    primaryColorElements: defaultStyles.primaryColorElements,
    primaryColorHeaders: defaultStyles.primaryColorHeaders,
  };

  if (!primaryColor) {
    return defaultValues;
  }

  if (!validateHexColor(primaryColor)) {
    console.error('Invalid primaryColor: insert a valid HEX color code.');

    return defaultValues;
  }

  return {
    primaryColorElements: primaryColor,
    primaryColorHeaders: primaryColor,
  };
};
