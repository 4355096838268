import { nanoid } from 'nanoid';
import { useRouter } from 'next/router';

import { useStorage } from 'hooks/useStorage';

const lamiTokenKey = 'lamiId';

export const useLamiId = () => {
  const router = useRouter();
  const { get, set } = useStorage();

  const getLamiId = () => {
    if (router.isReady && router.query?.lamiId) {
      const userId = router.query?.lamiId as string;

      set(lamiTokenKey, userId);

      return userId;
    }

    const lamiTokenValue = get(lamiTokenKey);

    if (lamiTokenValue) {
      return lamiTokenValue;
    }

    const userHash = nanoid();

    set(lamiTokenKey, userHash);

    return userHash;
  };

  return { getLamiId };
};
