import React, { createContext, useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';

interface IsWidgetCtx {
  isWidget: boolean | null;
  isWidgetFirstRender: string | string[] | undefined;
  parentOrigin: string;
}

const IsWidget = createContext({} as IsWidgetCtx);

export const IsWidgetProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [isWidget, setIsWidget] = useState<boolean | null>(null);
  const [parentOrigin, setParentOrigin] = useState<string>('');

  const {
    query: { widget, origin },
    isReady,
  } = useRouter();

  useEffect(() => {
    setIsWidget(!(window === window.parent || window.opener));
  }, []);

  useEffect(() => {
    if (isReady && origin) setParentOrigin(origin as string);
  }, [isReady, origin]);

  return (
    <IsWidget.Provider
      value={{ isWidget, isWidgetFirstRender: widget, parentOrigin }}
    >
      {children}
    </IsWidget.Provider>
  );
};

export const useIsWidget = () => {
  const context = useContext(IsWidget);

  if (!context) {
    throw new Error('useIsWidget hook must be used under IsWidgetProvider');
  }

  return context;
};
